import React from "react";
import theme from "theme";
import { Theme, Text, Box, Image, Section, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"contacts"} />
		<Helmet>
			<title>
				Clip & Clove Barbershop
			</title>
			<meta name={"description"} content={"Зробіть крок у світ, де традиції зустрічаються з трендами, а кожна стрижка є символом стилю та витонченості. У Clip & Clove Barbershop ми віримо, що чудова стрижка є наріжним каменем чудового дня. Незалежно від того, готуєтеся ви до важливої ​​події чи просто бажаєте оновити свій образ, наші двері відкриті, щоб надати вам неперевершений досвід догляду."} />
			<meta property={"og:title"} content={"Clip & Clove Barbershop"} />
			<meta property={"og:description"} content={"Зробіть крок у світ, де традиції зустрічаються з трендами, а кожна стрижка є символом стилю та витонченості. У Clip & Clove Barbershop ми віримо, що чудова стрижка є наріжним каменем чудового дня. Незалежно від того, готуєтеся ви до важливої ​​події чи просто бажаєте оновити свій образ, наші двері відкриті, щоб надати вам неперевершений досвід догляду."} />
			<meta property={"og:image"} content={"https://uploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-2.jpg?v=2024-06-14T11:09:03.574Z"} />
			<link rel={"shortcut icon"} href={"https://uploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-2.jpg?v=2024-06-14T11:09:03.574Z"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://uploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-2.jpg?v=2024-06-14T11:09:03.574Z"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://uploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-2.jpg?v=2024-06-14T11:09:03.574Z"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://uploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-2.jpg?v=2024-06-14T11:09:03.574Z"} />
			<link rel={"apple-touch-startup-image"} href={"https://uploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-2.jpg?v=2024-06-14T11:09:03.574Z"} />
			<meta name={"msapplication-TileImage"} content={"https://uploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-2.jpg?v=2024-06-14T11:09:03.574Z"} />
			<meta name={"msapplication-TileColor"} content={"#232323"} />
		</Helmet>
		<Components.Header23>
			<Override slot="text1" />
			<Override slot="link">
				0967201181
			</Override>
			<Override slot="text2" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
			<Override slot="text" />
		</Components.Header23>
		<Section padding="60px 0 60px 0" md-padding="30px 0 30px 0" background="--color-darkL1">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-min-width="64px"
				padding="0px 90px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				width="50%"
				lg-padding="0px 28px 0px 0px"
				empty-min-height="64px"
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				align-self="center"
			>
				<Text
					font="--headline2"
					lg-font="normal 500 32px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					md-font="normal 500 28px/1.2 --fontFamily-googleAlegreyaSans, sans-serif"
					margin="0px 0px 32px 0px"
					color="--light"
				>
					Готові покращити свій стиль?
				</Text>
				<Text
					color="--light"
					font="--lead"
					text-align="left"
					lg-font="normal 400 16px/1.5 &quot;Varta&quot;, sans-serif"
					md-margin="0px 0px 16px 0px"
					margin="0px 0px 0px 0px"
				>
					Приходьте відчути мистецтво догляду в Clip & Clove Barbershop. Наша команда експертів готова допомогти вам досягти бажаного вигляду.{" "}
				</Text>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				empty-border-color="LightGray"
				width="50%"
				md-width="100%"
				empty-min-width="64px"
				empty-min-height="64px"
			>
				<Image
					src="https://uploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-3.jpg?v=2024-06-14T11:09:03.585Z"
					width="100%"
					max-height="auto"
					object-fit="cover"
					lg-min-height="300px"
					md-max-height="277px"
					md-min-height="16px"
					srcSet="https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-3.jpg?v=2024-06-14T11%3A09%3A03.585Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-3.jpg?v=2024-06-14T11%3A09%3A03.585Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-3.jpg?v=2024-06-14T11%3A09%3A03.585Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-3.jpg?v=2024-06-14T11%3A09%3A03.585Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-3.jpg?v=2024-06-14T11%3A09%3A03.585Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-3.jpg?v=2024-06-14T11%3A09%3A03.585Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-3.jpg?v=2024-06-14T11%3A09%3A03.585Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" md-padding="20px 0 12px 0" background="--color-darkL2">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" />
			<Box
				empty-border-color="LightGray"
				padding="0px 20px 0px 0px"
				md-padding="0px 0px 0px 0px"
				md-width="100%"
				empty-min-width="64px"
				empty-border-style="solid"
				width="50%"
				empty-min-height="64px"
				empty-border-width="1px"
			>
				<Image
					object-fit="cover"
					lg-max-height="230px"
					src="https://uploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-2.jpg?v=2024-06-14T11:09:03.574Z"
					max-height="400px"
					width="100%"
					srcSet="https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-2.jpg?v=2024-06-14T11%3A09%3A03.574Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-2.jpg?v=2024-06-14T11%3A09%3A03.574Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-2.jpg?v=2024-06-14T11%3A09%3A03.574Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-2.jpg?v=2024-06-14T11%3A09%3A03.574Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-2.jpg?v=2024-06-14T11%3A09%3A03.574Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-2.jpg?v=2024-06-14T11%3A09%3A03.574Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/1-2.jpg?v=2024-06-14T11%3A09%3A03.574Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Box
					empty-min-height="64px"
					empty-border-color="LightGray"
					width="100%"
					empty-min-width="64px"
					empty-border-width="1px"
					empty-border-style="solid"
					margin="40px 0px 0px 0px"
					lg-width="70%"
					md-width="100%"
				>
					<Text margin="0px 0px 16px 0px" color="--light" font="--base" text-align="left">
						Запишіться на наступний прийом сьогодні і пориньте у світ вишуканого догляду, створеного спеціально для вас. Дізнайтеся, чому наші клієнти обирають Clip & Clove як місце для догляду.{" "}
					</Text>
				</Box>
			</Box>
			<Box
				empty-border-width="1px"
				empty-border-style="solid"
				width="50%"
				md-width="100%"
				md-padding="0px 0px 0px 0px"
				empty-min-width="64px"
				empty-min-height="64px"
				empty-border-color="LightGray"
				padding="0px 0px 0px 20px"
			>
				<Image
					src="https://uploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-3.jpg?v=2024-06-14T11:09:03.586Z"
					max-height="400px"
					width="100%"
					object-fit="cover"
					lg-max-height="230px"
					srcSet="https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-3.jpg?v=2024-06-14T11%3A09%3A03.586Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-3.jpg?v=2024-06-14T11%3A09%3A03.586Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-3.jpg?v=2024-06-14T11%3A09%3A03.586Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-3.jpg?v=2024-06-14T11%3A09%3A03.586Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-3.jpg?v=2024-06-14T11%3A09%3A03.586Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-3.jpg?v=2024-06-14T11%3A09%3A03.586Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/666c07d18d4a0c0020306efc/images/2-3.jpg?v=2024-06-14T11%3A09%3A03.586Z&quality=85&w=3200 3200w"
					sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
				/>
				<Box
					empty-min-height="64px"
					empty-border-color="LightGray"
					width="100%"
					empty-min-width="64px"
					empty-border-width="1px"
					empty-border-style="solid"
					margin="40px 0px 0px 0px"
					lg-width="70%"
					md-width="100%"
				>
					<Text margin="0px 0px 16px 0px" color="--light" font="--headline5" text-align="left">
						Адреса: Kapushanska St, 87, Uzhhorod, Zakarpattia Oblast, 88000
						<br />
						<br />
						Номер телефону:  0967201181
					</Text>
					<Button
						background="--color-green"
						border-radius="13px"
						min-width="200px"
						max-width="100%"
						margin="0px auto 0px auto"
						text-align="center"
						type="link"
						text-decoration-line="initial"
						href="/menu"
					>
						ПОСЛУГИ
					</Button>
				</Box>
			</Box>
		</Section>
		<Components.Footer1>
			<Override slot="text1">
				Kapushanska St, 87, Uzhhorod, Zakarpattia Oblast, 88000
			</Override>
			<Override slot="link1">
				0967201181
			</Override>
		</Components.Footer1>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"6166a2b829a0a1001e6ca5fb"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});